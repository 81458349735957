import webBiLogger from '@wix/web-bi-logger';
import { bookingsEditorScript } from '@wix/bi-logger-wixboost-ugc/v2';

const logger = webBiLogger.factory().logger();

export enum Actions {
  BOOKINGS_INSTALLATION = 'bookings-installation',
  MEMBERS_AREA_INSTALLATION = 'members-area-installation',
  ECOM_INSTALLATION = 'ecom-installation',
  CREATE_EDITOR_SCRIPT = 'create-editor-script',
  EDITOR_READY = 'editor-ready',
  EDITOR_READY_CALLBACK = 'editor-ready-callback',
  CART_INSTALLATION = 'cart-installation',
  MARK_CART_ENABLED = 'mark-cart-enabled',
  FIX_SITE = 'fix-site',
  ECOM_SELF_MIGRATION = 'ecom-self-migration',
  APP_INSTALLED = 'app-installed',
  APP_REMOVED = 'app-removed',
  MIGRATE = 'migrate',
  HANDLE_ACTION = 'handle-action',
  GET_APP_MANIFEST = 'get-app-manifest',
  ON_EVENT = 'on-event',
}

export type ReportBi = {
  msid?: string;
  origin?: string;
  isFirstInstall?: boolean;
  actionType: Actions;
  actionSucceed: boolean;
  errorMessage?: string;
};

export const reportBi = (input: ReportBi) => {
  logger.report(bookingsEditorScript(input));
}
